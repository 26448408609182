import React from 'react'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
// import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

// import Nav from '../components/Nav'
import pic01 from '../assets/images/pic01.jpg'

const btnStyle = {
  backgroundColor: '#d065b5',
  color: 'white',
  fontWeight: 'bold',
  borderColor: 'white',
  width: '100%',
  boxShadow: 'none',
}
const btnWrapStyle = {
  width: '92%',
  textAlign: 'center',
  position: 'absolute',
  margin: 'auto',
  marginTop: '-3rem',
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Future Transit, Thunder Bay Transit Services, tbtfuture.fyi" />

        <HeaderGeneric title="Transit Survey" />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Tell us what you think!</h2>
                </header>
                <p>
                  After reviewing the information on the main page of this
                  website, as well as the interactive map of the proposed bus
                  network, please complete this short survey to tell us:
                  <ul>
                    <li>
                      How well we’ve addressed your concerns for the current bus
                      network
                    </li>
                    <li>Which routes you currently use</li>
                    <li>
                      And which routes you would use in the proposed bus
                      network.
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>
                    <em>Survey will close Feb. 15</em>
                  </strong>
                </p>
              </div>
              <div className="image-w-button">
                <span className="image">
                  <a href="https://www.surveymonkey.com/r/tbtfuture">
                    <img src={pic01} alt="" />
                    <div style={btnWrapStyle}>
                      <div className="button" style={btnStyle}>
                        start the survey NOW
                      </div>
                    </div>
                  </a>
                </span>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
